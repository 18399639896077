import React from 'react'
import {Navbar, Brand, CTA, Feature} from './component';
import {Footer, Blog, Header, Features, Possibility, What} from './container';
import './App.css';
const App = () => {
  return (
    <div className="App">
        <div className="gradient__bg">
            <Navbar />
            <Header />
        </div>
        <Brand />
        <What />
        <Features />
        <Possibility />
        <CTA />
        <Blog />
        <Footer />
        <Feature />
    </div>
  )
}

export default App
