import React from 'react'
import './header.css'
import people from '../../assets/people.png';
import ai from '../../assets/ai.png'
const Header = () => {
  return (
    <div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-content">
        <h1 className="gradient__text">Let's build something amazing ! </h1>
        <p>GPT-3 is a state-of-the-art language processing AI model developed by OpenAI</p>
        <div className="gpt3__header-content__input">
          <input type="email" placeholder="Your Email Address"/>
          <button type ="button">Get started</button>
        </div>
        <div className="gpt3__header-content__people">
          <img src={people} alt="people"/>
          <p>1600 people requested access to this website</p>
        </div>
      </div>
      <div className="gpt3__header-image">
        <img src={ai} alt="ai"/>
      </div> 
      
    </div>
  )
}

export default Header
